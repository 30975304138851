hr {
    border-top: solid 1px var(--primary);
    margin-top: 8px;
}
.message {
    border: solid 1px var(--info);
    background: var(--light);
    padding: 0.5em;
    border-radius: 0.75em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.detail-message,
.destinataires {
    color: var(--primary);
}
