.org-selector-container {
    width: 55%;
    margin: 20px auto;
}

.organization-container {
    margin-top: 2em;
    width: 40%;
}

.choice-container {
    max-height: 250px;
    overflow: scroll;
    overflow-x: hidden;
}
.choose-organization {
    background-color: #f0f0f0;
    margin: 0.5em auto;
}

.logo-org {
    margin: 1em;
    width: 152px;
}

.choose-org-title {
    margin: 0.5em;
    padding: 0.5em;
    padding-bottom: 0px;
    background-color: #f0f0f0;
    color: var(--primary);
    text-align: center;
    font-size: 19px;
}

.org-choice {
    color: #787878;
    font-size: 15px;
    padding: 2px;
    background-color: white;
    padding: 4px;
    transition: 0.5s all;
    cursor: pointer;
}

.org-choice:hover {
    background-color: var(--primary);
    filter: brightness(115%);
    color: white;
    font-size: 15.1px;
}

.org-items-container {
    position: relative;
    top: -2em;
}

.logo-list {
    margin: 0.5em;
    width: 80px;
    position: relative;
    top: -2px;
}

.container-organization {
    height: calc(100vh - 4em);
}
