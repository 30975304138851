.container-group {
    padding: 20px;
}
.container-group h1 {
    font-size: 1.2rem;
}
.icon-add-member {
    margin-right: 0.3em;
}
.title-add-member {
    cursor: pointer;
}
.add-member .search-icon {
    right: 25px;
}
