.message-doc-container {
    height: auto;
    max-height: 40px;
    display: flex;
    margin-right: 15px;
    border: 2px solid lightgrey;
    border-radius: 7px;
    padding: 5px;
    background: #f0f0f0;
    color: black;
    margin-bottom: 8px;
}

.message-doc-icon {
    align-items: center;
    display: flex;
}

.message-doc-title {
    width: max-content;
    font-size: 0.88em;
    margin: 0px 5px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
    line-height: 15px;
    @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.other-message-doc-title {
    width: max-content;
    font-size: 0.88em;
    margin: 0px 5px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
    line-height: 15px;
    @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.message-download {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}
.message-download:hover {
    color: black;
}

.other-message-download {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}

.other-message-download:hover {
    color: black;
}

.input-message-doc-container {
    height: 35px;
    display: flex;
    margin-right: 8px;
    margin-bottom: 5px;
    border: 2px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    background: #f0f0f0;
    color: black;
}

.input-message-doc-icon {
    display: flex;
    align-items: center;
}
.input-message-doc-icon > .k-icon-md {
    font-size: 27px;
}

.close-doc {
    cursor: pointer;
    position: relative;
    left: -20px;
    top: -3px;
}

.file-pdf > .k-svg-icon > svg {
    fill: #c50606 !important;
}
.file-word > .k-svg-icon > svg {
    fill: #2a5695 !important;
}
.file-excel > .k-svg-icon > svg,
.file-csv > .k-svg-icon > svg {
    fill: #008040 !important;
}
.file-txt > .k-svg-icon > svg {
    fill: black !important;
}
.file-ppt > .k-svg-icon > svg {
    fill: #d65132 !important;
}
