#iframe {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
    height: calc(100vh - 4em);
}
.container-keycloak {
    height: 100%;
    display: flex;
}
.centerizedLoader {
    display: inline-block;
    top: calc(50%);
    left: calc(50%);
    position: fixed;
}
