.lock{
    transform: scale(0.55);
    position: relative;
    right:1em;
    top: -0.108em;
    animation: 1s lockAnimation;
}

.lock-bubble{
    color:var(--alert);
    animation: 2s lockBubbleAnimation;
}

.lock-bubble , .lock{
    z-index:999;
}
@keyframes lockBubbleAnimation {
    0%{
        color:black;
    }
    100%{
        color:var(--alert);
    }
}
@keyframes lockAnimation {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.unavailable-avatar{
    position: relative;
    top: 18px;
    left: -9px;
}