$border-radius: 0.25rem;
$dark-theme: false;
$primary: #0a2463;
$secondary: #6c6c6c;
$tertiary: #99ccff;
$info: #2375ff;
$success: #198754;
$warning: #ffc107;
$error: #fb3640;
$body-text: #6c6c6c;
$body-bg: #ffffff;
$headings-text: #0a2463;
$subtle-text: #6c6c6c;
$disabled-text: #8f8f8f;
$component-text: #212529;
$component-bg: #ffffff;
$base-text: #212529;
$base-bg: #f8f9fa;
$hovered-text: #16181b;
$hovered-bg: #e9ecef;
$selected-text: #ffffff;
$selected-bg: #2375ff;
$kendo-button-text: #ffffff;
$kendo-button-bg: #0a2463;
$link-text: #2375ff;
$link-hover-text: #0a58ca;
$series-a: #0d6efd;
$series-b: #6f42c1;
$series-c: #20c997;
$series-d: #198754;
$series-e: #ffc107;
$series-f: #dc3545;
$white: #fff;
$black: #000;
$light: #f4f4f4;
$red: #fb3640;
$alert: #fb3640;

@import '../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../node_modules/@progress/kendo-theme-bootstrap/dist/all.scss';
@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import 'App.scss';
