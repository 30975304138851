.typing-chat-bubble {
    background-color: #fff;
    padding: 16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 12px;
    border-bottom-left-radius: 2px;
    display: inline-block;
    width: 80%;
}
.typing {
    align-items: center;
    display: flex;
    height: 17px;
}
.typing .dot {
    animation: typingAnimation 1.8s infinite ease-in-out;
    background-color: var(--clr-textb);
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}
.typing .dot:nth-child(1) {
    animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
    animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
    animation-delay: 400ms;
}
.typing .dot:last-child {
    margin-right: 0;
}
.noMessage {
    position: absolute;
    bottom: 5em;
    left: calc(50% + 85px);
}
@keyframes typingAnimation {
    0% {
        transform: translateY(0px);
        background-color: rgba(52, 161, 235, 0.7);
    }
    28% {
        transform: translateY(-7px);
        background-color: rgba(43, 133, 194, 0.4);
    }
    44% {
        transform: translateY(0px);
        background-color: rgba(24, 76, 112, 0.2);
    }
}

.original-message-animation-add-red {
    border: 1px solid var(--red);
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.5s;
    border-radius: 12px;
    display: flex;
    justify-content: end;
}
.original-message-animation-delete-red {
    display: flex;
    justify-content: end;
}
