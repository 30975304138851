.chat-container {
    background-color: var(--bg-light);
    display: flex;
    flex: 1;
    overflow: hidden;
}

.conv-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 4em);
}

.sidebar {
    background-color: var(--white);
    box-sizing: border-box;
    overflow-y: auto;
    width: 24em;
}

.chat {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.chat > h2 {
    margin: 15px;
}
