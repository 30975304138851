.chat-bubble {
    font-size: 14px;
    height: auto;
    min-height: 40px;
    background-color: rgb(10, 36, 99);
    border-radius: 12px;
    color: white;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    margin: 5px;
    padding: 5px;
    overflow-wrap: break-word;
    word-break: break-word;
    user-select: none;
    transition: all 0.5s;
}

.chat-bubble-top-info-section {
    justify-content: space-between;
    display: flex;
}

.chat-bubble:hover {
    -webkit-box-shadow: 5px 5px 4px -3px rgba(0, 0, 0, 0.6);
    box-shadow: 2px 2px 3px -2px rgba(0, 0, 0, 0.6);
}

.message-content {
    padding-left: 4px;
    width: 100%;
    padding: 4px;
    user-select: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-messageContent {
    /* width:900px; */
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.35;
    text-align: left;
}

.k-bubble-box {
    width: 90%;
}
.chat-message-box {
    display: flex;
    justify-content: end;
}

#other {
    justify-content: start !important;
}

#other-chat-bubble {
    color: var(--bs-primary);
    background: white;
}

.non-read-message {
    border: 1px solid var(--red);
}

.non-read-message .message-content {
    font-weight: bold;
}

.message-content {
    cursor: pointer;
}

.dc-time {
    color: var(--clr-textb);
    font-size: smaller;
    line-height: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 4px;
    text-align: right;
}

.status-message {
    font-size: 12px;
    margin-top: 25px;
    margin-right: 8px;
    text-align: right;
    white-space: nowrap;
}

.k-author {
    font-weight: 500;
    margin-top: 5px !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
    margin-bottom: 4px !important;
    text-align: right;
}

.dc-clipboard {
    transform: scale(1.5);
    height: 24px;
    cursor: pointer;
    transition: all 0.5s;
    background-color: white;
}

.dc-clipboard:hover {
    height: 24.08px;
    transform: scale(1.5) translateY(-1.8px);
}

.img-bubble {
    width: auto;
    max-height: 40px;
    padding: 1px;
    cursor: pointer;
    transition: all 0.5s;
    user-select: none;
    margin-right: 4px;
    margin-bottom: 10px;
    float: left;
}

.img-bubble-reply {
    width: auto;
    max-height: 40px;
    padding: 1px;
    cursor: pointer;
    transition: all 0.5s;
    user-select: none;
    margin-right: 4px;
    margin-bottom: 10px;
    float: left;
}

.img-bubble-noData {
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 1px;
    transition: all 0.5s;
    margin-right: 4px;
    margin-bottom: 10px;
    float: left;
    border: solid 1px;
    border-radius: 7px;
    color: black;
}

.img-bubble:hover {
    transform: scale(1.03);
}

.chat-space {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* margin-left: 10px; */
}

.chat-space > p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.moreHorizontal-div {
    padding-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.moreHorizontal-item {
    height: 25px;
    margin-left: 10px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s;
    transform: scale(1.5);
}

.chat-bubble:hover>.message-content>.chat-space>.moreHorizontal-div>.moreHorizontal-item {
    opacity: 1;
}

#other-dc-time {
    text-align: left;
    margin-left: 4px;
}

#other-status-message {
    text-align: left;
    margin-left: 4px;
}

.other-author {
    text-align: left !important;
}

#other-chat-bubble {
    color: var(--clr-textb);
    background-color: white;
}

.big-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.big-img-size {
    width: auto;
    height: auto;
    max-width: 500px;
    max-height: 350px;
}

/* Kendo-UI CSS */

.k-scrollview-view {
    text-align: center;
    border: none !important;
    border-style: none !important;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.k-window-content {
    padding: 0 !important;
}

.rotate-reply-icon {
    .k-icon {
        transform: scale(1, -1);
    }
}

.message-text-being-replied-to {
    text-align: left;
    margin-left: 20px;
}
.message-text-being-replied-to-box {
    margin-left: 10px;
    margin-right: 30%;
    background-color: #f0f0f0;
    outline: 2px solid lightgrey;
    border-radius: 10px;
}
.displayRow {
    display: table;
    table-layout: fixed;
    border-spacing: 5px;
}
.displayRow-message {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 5px;
}

.displayColumn {
    display: table-cell;
    background-color: #f0f0f0;
}
.silverLine {
    width: 4px;
    background-color: var(--disabled);
    border-radius: 2px;
}
.silverLine-forward {
    width: 4px;
    border-radius: 2px;
}
.close-reply {
    margin-top: 0px;
    margin-left: 25px;
    width: 30px;
    vertical-align: text-top;
    height: 10px;
}
.close-reply-icon {
    margin-top: 5px;
    border: 0;
}
.input-bar-reply-action {
    border: 0px;
}
.user-name-date-close-bt {
    border: 0px;
    width: 100%;
}
.message-user-name-date {
    padding-left: 0px;
}
.message-to-reply-to-date {
    font-size: 13px;
    color: var(--secondary);
    float: right;
}
.message-to-reply-to-username {
    font-weight: 500;
    margin-top: 5px;
    margin-left: 8px;
    margin-bottom: 4px;
    float: left;
}
.message-c-input-img-reply {
    margin-left: 10px;
}
.message-c-input-text-reply {
    text-align: justify;
    padding: 0px 5px 0 5px !important;
}

.message-c-input-text-forward {
    text-align: justify;
    padding: 0px 5px 0 8px !important;
}

.forwardIcon {
    width: 0.5em !important;
    height: 0.5em !important;
    margin-top: -3px;
    color: var(--disabled);
}

.forwardIcon-on-reply {
    width: 0.5em !important;
    height: 0.5em !important;
    margin-top: -3px;
    color: var(--disabled);
    margin-left: 5px;
}
.download-icon-container {
    width: 500px;
    height: 50px;
    background-color: #0a2463;
    text-align: center;
}

.download-icon-style {
    color: #fff;
    top: 10px;
}

kendo-scrollview.k-scrollview-wrap kendo-scrollview-pager,
kendo-scrollview.k-scrollview kendo-scrollview-pager,
.k-scrollview-nav-wrap {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(10px + 0px + 40px);
    text-align: center;
    top: 448px !important;
}

.message-msgdoc-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
}

.k-link,
.k-link:hover {
    color: inherit;
    text-decoration: none;
    outline: 0;
    cursor: pointer !important;
}

.download-icon-container > span > svg {
    cursor: pointer;
}
.doc-reply-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
