:root {
    --clr-alerte: #{$alert};
    --clr-btn1: #{$primary};
    --clr-hbtn1: #051336; /*On peut utiliser filter: brightness(85%) pour le hover darken, ou 115% pour éclaircir;*/
    --clr-textb: #{$primary};
    --bg-light: #{$light};
    --primary: #{$primary};
    --secondary: #{$secondary};
    --tertiary: #{$tertiary};
    --info: #{$info};
    --success: #{$success};
    --warning: #{$warning};
    --error: #{$error};
    --white: #{$white};
    --light: #{$light};
    --red: #{$red};
    --alert: #{$alert};
    --black: #{$black};
    --disabled: #{$disabled-text};
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.search-icon-grey {
    fill: #bbbbbb !important;
    flex: 0 1 auto !important;
}

.container {
    background: #{$white};
}
.bg-primary {
    background-color: #{$primary};
}
.bg-secondary {
    background-color: #{$secondary};
}
.bg-tertiary {
    background-color: #{$tertiary};
}
.bg-info {
    background-color: #{$info};
}
.primary {
    color: #{$primary};
}
.secondary {
    color: #{$secondary};
}
.tertiary {
    color: #{$tertiary};
}
.alert {
    color: #{$alert};
}
.vertical-top-space {
    margin-top: 1rem;
}
.vertical-top-space2 {
    margin-top: 2rem;
}
.vertical-bottom-space {
    margin-bottom: 1rem;
}
.screen-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
}
.k-form-legend {
    text-align: left;
}
.k-input:focus,
.k-input.k-focus {
    box-shadow: 0 0 0 0.15rem rgba(10, 36, 99, 0.25) !important;
}
.k-floating-label-container {
    padding-bottom: 0.2rem; /*Évite les bugs de shadow bottom sur focus*/
}
.dark-over:hover {
    filter: brightness(85%);
}
.light-over:hover {
    filter: brightness(125%);
}
.link-over:hover {
    text-decoration: underline;
}
.no-over:hover {
    background: inherit !important;
}
.k-appbar-light {
    background-color: #{$primary};
    color: #{$white};
}
.k-window-titlebar {
    background: #{$primary};
    color: #{$white};
}
.k-window,
.k-button {
    border-width: 0;
}
.hide {
    display: none;
}
.show {
    display: block;
}
input {
    padding: 1px 5px;
}
.center,
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}
.clickable {
    cursor: pointer !important;
}
.fake-button {
    cursor: default !important;
    background: none !important;
}
.disabled-click {
    cursor: default !important;
}
.no-padding {
    padding: 0 !important;
}
.k-dialog-title {
    margin-right: 0.5em;
}
.container-modal {
    margin: 25px;
}