.chat-mid {
    /* max-width: 400px; */
    /* overflow: scroll; */
    /* max-width: 65%; */
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.chat-feed {
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    flex: 1;
}

.chat-feed-count {
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    flex: 1;
}

.chat-footer {
    box-sizing: border-box;
    flex-shrink: 0;
}

.chat-footer-big-image {
    padding: 8px;
    min-width: -webkit-fill-available;
}

.scroll-to-bottom-container {
    width: 50px;
    position: absolute;
    right: 0px;
}

.scroll-to-bottom-button {
    border-radius: 50%;
    height: 33px;
    width: 33px;
    position: relative;
    bottom: 2.5em;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.37);
    z-index: 999;
    transition: all 0.5s;
}

.scroll-to-bottom-button > span {
    margin-top: 0.55em;
}

.scroll-to-bottom-button-message {
    height: 33px;
    position: relative;
    right: 10em;
    bottom: 2.5em;
    cursor: pointer;
    background-color: #99ccff;
    -webkit-box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.37);
    z-index: 999;
    transition: all 0.5s;
    width: 12em;
    border-radius: 6px;
    padding-top: 4px;
}
.scroll-to-button-icon {
    width: 3px;
    margin-right: 10px;
    transform: scale(1.4);
}
.scroll-to-bottom-button-message > span {
    margin-left: 8px;
    font-size: 14px;
}
