::-webkit-scrollbar {
    width: 10px;
    display: block;
    height: 2em;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(10, 36, 99);
    width: 5px;
    height: 8px;
}

.cr-nav {
    background-color: red;
    color: white;
    padding: 2px;
    transition: all 0.5s;
}

.loading:after {
    content: ' .';
    animation: dots 3s steps(5, end) infinite;
}

@keyframes dots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: white;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
}

.cg-image-dropdown {
    width: 25px;
    height: 25px;
    margin-right: 2.5px;
}
.cg-chat-perso {
    width: 60px;
    height: 60px;
    object-fit: fill;
    margin-right: 10px;
    transition: all 0.5s;
}

.cdt {
    padding: 15px;
    height: 120px;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
}

.cdt:hover {
    background-color: white;
}

.cg-nom {
    margin: 5px;
    color: rgb(10, 36, 99);
    font-weight: 700;
    text-align: left;
    max-width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cg-chat {
    /* justify-content: space-between; */
    display: flex;
}

.cg-top-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cdt.important .cg-chat .cg-temps {
    color: var(--red);
    font-weight: bold;
}

.cdt.important .cdt-bas .message-recent {
    color: var(--red);
    font-weight: bold;
}

.rightbarOnline {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: limegreen;
    position: absolute;
    border: 2px solid var(--white);
}
.selected {
    opacity: 1;
    background-color: var(--tertiary) !important;
    transition: all 0.5s;
    transform: translateY(-0.9px);
}

.selected .cg-supprimer {
    background-color: var(--tertiary) !important;
}

.rightbarOffline {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--red);
    position: absolute;
    border: 2px solid var(--white);
}

.cdt-bas {
    width: 97%;
    justify-content: space-between;
    display: flex;
    margin-left: auto;
    /* //TODO: Refaire le display des conversations */
    max-height: 40px;
    overflow: hidden;
}

.cg-temps {
    color: var(--secondary);
    font-size: 0.8em;
    text-align: right;
}

.message-recent {
    color: var(--secondary);
    font-size: 0.9em;
    max-width: 14em;
}

.message-recent-non-available {
    color: var(--secondary);
    font-size: 0.9em;
}
.message-recent > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cg-supprimer {
    background-color: transparent;
    border: none;
    opacity: 0;
    transition: all 0.5s;
    /*padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--white);*/
}

.display-trash {
    display: block;
}

.hover .cg-supprimer {
    display: block;
    opacity: 1;
}

.received .cg-temps {
    font-weight: bold;
    color: var(--red);
}
.received .message-recent {
    font-weight: bold;
    color: var(--red);
}
/*.cg-supprimer:hover {
    opacity: 1;
    transform: translateY(-3px);
}*/

.trash-icon:hover {
    color: var(--black) !important;
    transition: all 0.5s;
}
/*
CSS de displayConversation.css
*/

.chat {
    margin: 0 !important;
}

.k-chat {
    max-width: 100% !important;
    height: calc(95vh - 4em) !important;
    background-color: #f4f4f4 !important;
    border-style: none !important;
}

@media screen and (max-width: 1400px) {
    .k-chat {
        max-width: 100% !important;
        height: calc(92vh - 4em) !important;
        background-color: #f4f4f4 !important;
        border-style: none !important;
    }
}

.k-timestamp {
    display: none;
    user-select: none;
}

.k-bubble {
    background-color: rgb(10, 36, 99) !important;
    border-color: rgb(10, 36, 99) !important;
    color: white !important;
    margin-bottom: 20px;
    user-select: none;
    display: flex;
}

.text-bubble {
    display: flex;
}

.k-message {
    height: 81px;
}

.k-message-time {
    display: none;
    /*color: var(--clr-textb);
    position: relative;
    top: -10px !important;
    right: 0px !important;*/
}

.k-card-type-rich {
    margin-bottom: 20px;
}

.k-card-body > img {
    user-select: none !important;
}

@keyframes fade-in-text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*option image*/
.k-i-image-insert {
    display: none !important;
}

.send-image {
    position: absolute;
    z-index: 999;
    bottom: 6px;
    right: 44px;
}

.coller-icon {
    z-index: 999;
    position: absolute;
    right: 40px;
    cursor: pointer;
}

.display-item {
    opacity: 1 !important;
}

.display-none {
    opacity: 0 !important;
}

.status-message {
    position: relative;
    top: -19px;
    font-size: 12px;
    color: var(--clr-textb);
}

.k-button svg {
    transition: all 0.3s;
}

.chat-info-container {
    width: 100%;
}

.selec-icon-small {
    font-size: 1.2em;
    display: none !important;
}

.selected .selec-icon-small {
    float: right;
    display: inline-flex !important;
    transition: all 0.5s;
}