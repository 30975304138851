.btn-back-group {
    /* position: fixed; */
    margin: 16px;
    margin-left: 0;
    padding: 8px;
    padding-left: 0;
    border-radius: 0;
}
.back-arrow {
    margin-top: -3px;
    margin-left: -7px;
    font-size: 22px;
}
