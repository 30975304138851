.eula-container {
    margin: 15px;
    margin-left: 15%;
    margin-right: 15%;
}
.eula-agreement {
    height: calc(100vh - 8.8em);
    overflow-y: auto;
    border: 3px black solid;
}
.eula-text {
    margin: 15px;
}
.eula-title {
    color: rgba(223, 227, 237, 0.75);
    text-align: center;
}
.eula-title-section {
    background-color: rgb(15, 38, 95);
    margin-bottom: 20px;
}
