.c-input-container {
    /* width: 75%;  */
    /* bottom: 0; */
    display: flex;
    /* position: fixed; */
    background-color: white;
    height: 3.3em;
}

.input-bar {
    width: 100%;
    padding: 2px;
    outline: none;
    border: none;
    padding: 8px 8px;
    margin: 6px 0px 4px 6px;
    border: 2px solid lightgrey;
    z-index: 50;
    transition: all 0.5s;
    border-radius: 10px;
}

.input-bar-image {
    width: 100%;
    padding: 2px;
    outline: none;
    border: none;
    padding: 8px 8px;
    margin-top: 10px;
    margin-right: 5px;
    border: none;
    transition: all 0.5s;
    border-radius: 10px;
}

.c-send {
    z-index: 999;
    cursor: pointer;
    border: none;
    background: white;
    font-size: 20px;
    padding: 8px;
    transition: all 0.5s;
}

.c-send:hover {
    color: rgb(10, 36, 99);
}

.paste-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.paste {
    width: 1.5%;
    cursor: pointer;
    transition: all 0.5s;
}

.paste:hover {
    transform: scale(1.1);
}

.c-input-img {
    height: 40px;
    margin: 0 5px;
    cursor: pointer;
    user-select: none;
    /* top: -55px;
    position: relative;
    background: white;
    border-radius: 5px 5px 0px 0px; */
}

.opacity-on {
    opacity: 1 !important;
}

.supprimerImg {
    position: relative;
    top: -20px;
    left: -21px;
    font-size: 15px;
    font-weight: bold;
    z-index: 999;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s;
    border: 1px solid var(--clr-textb);
    color: var(--clr-textb);
    border-radius: 50%;
    width: 18px;
}

.supprimerImg:hover {
    opacity: 1;
}

.temp-img {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    position: relative;
    padding: 10px;
    border-radius: 5px;
    z-index: 100;
}

.reference-doc-container {
    display: flex;
    position: relative;
    padding-top: 0px;
    border-radius: 5px;
    z-index: 999;
}

.c-input-img {
    background-color: white;
}

.c-img-paste {
    height: 40px;
    margin: 0 4px;
    background-color: white;
}

.text-count {
    text-align: right;
    padding-right: 5px;
    color: var(--clr-textb);
    font-size: 14.5px;
    font-weight: 500;
}

.close-img {
    cursor: pointer;
    position: absolute !important;
    top: 0;
    right: 0;
}

.x-circle-Icone {
    position: relative;
    display: inline-block;
}

.container-close-img {
    background-color: var(--white);
    border-radius: 50%;
    width: 1em;
    height: 1em;
    position: absolute;
    top: -5px;
    right: -2px;
}

.unavail-lock {
    text-align: left;
    position: relative;
    top: 13px;
    right: -10px;
    width: 10%;
}

.non-dispo-input {
    display: flex;
    padding-left: 11px;
    padding-top: 6px;
    background-color: white;
    height: 4.8em;
}

.cg-temps-input {
    color: var(--alert);
    text-align: left;
    margin-left: 4px;
}

.cg-top-info-input {
    width: 100%;
}

.cg-top-info-input-non-available {
    transform: scale(0.98);
    width: 100%;
}

.show-none {
    display: none;
}

.cg-chat-perso-input {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.input-date {
    font-size: 14px;
    color: var(--primary);
    font-size: 0.8em;
    text-align: right;
    padding-right: 7px;
}

.cg-nom-input {
    margin: 5px;
    color: rgb(10, 36, 99);
    font-weight: 700;
    text-align: left;
    margin-bottom: 0;
}

.image-text-container {
    outline: 2px solid lightgrey;
    border-radius: 10px;
    padding-top: 10px;
    background-color: white;
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-color: var(--primary) #fff;
}

.input-small-container {
    display: flex;
    width: 100%;
}

.hide-count {
    display: none;
}

.loader-container {
    position: relative;
    top: -5px;
}

.message-text-being-replied-to {
    text-align: left;
    margin: 0px 10px 0px 10px;
    text-align: justify;
}
.message-text-being-replied-to-box {
    position: relative;
    margin-left: 10px;
    margin-right: 30%;
    background-color: #f0f0f0;
    outline: 2px solid lightgrey;
    border-radius: 10px;
    color: black;
}
.image-list-container {
    position: relative;
    padding: 10px;
    width: 100%;
}
.displyRow {
    display: table;
    table-layout: fixed;
    border-spacing: 5px;
    text-align: left;
}
.displayColumn {
    display: table-cell;
    background-color: #f0f0f0;
}
.silverLine {
    width: 4px;
    background-color: var(--disabled);
    border-radius: 2px;
}
.close-reply {
    margin-top: 0px;
    margin-left: 25px;
    width: 30px;
    vertical-align: text-top;
    height: 10px;
}
.close-reply-icon {
    margin-top: 5px;
    border: 0;
}
.input-bar-reply-action {
    border: 0px;
}
.user-name-date-close-bt {
    border: 0px;
    width: 100%;
}
.message-user-name-date {
    padding-left: 0px;
}
.message-to-reply-to-date {
    font-size: 13px;
    color: var(--secondary);
    float: right;
}
.message-to-reply-to-username {
    font-weight: 500;
    margin-top: 5px;
    margin-left: 8px;
    margin-bottom: 4px;
    float: left;
    color: black;
}
.c-input-img-reply {
    margin-left: 20px;
}

.bigger-input {
    display: flex;
    align-items: center;
}

.small-input {
    width: 100%;
    padding: 2px;
    outline: none;
    border: none;
    margin-left: 6px;
    border: none;
    z-index: 50;
    transition: all 0.5s;
    border-radius: 10px;
    background: white;
    padding-top: 5px;
}

.bigger-input > span {
    cursor: pointer;
    transform: scale(1.21);
}

.input-msgdoc-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 5px;
}
.file {
    display: flex;
    flex: 0 0 auto;
    padding: 2px;
}

.message-text-image-to-reply-to > .temp-img {
    margin-bottom: 5px;
}
